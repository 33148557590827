<template>
  <Fragment>
    <p
      style="background-color: #ffffff"
      class="py-6 px-6 my-0 mx-0"
      v-if="!srcComputed"
    >
      No se ha seleccionado un documento
    </p>
    <div
      v-else
      class="px-4 py-0 pb-10"
      style="background-color: #ffffff; position: relative; line-height: 0"
    >
      <div
        class="py-4 my-5"
        style="
          width: 100%;
          background-color: inherit;
          position: sticky;
          top: 0;
          z-index: 10;
        "
      >
        <v-tooltip v-if="permitirDescargar" bottom>
          <template v-slot:activator="{ on, attrs }">
            <a
              class="d-flex align-center justify-center px-2 py-2 rounded-pill"
              style="
                width: 36px;
                height: 36px;
                float: right;
                background-color: var(--v-primary-base);
              "
              v-bind="attrs"
              v-on="on"
              target="_blank"
              :href="src"
              :download="descargarArchivo"
            >
              <v-icon color="#FFFFFF !important" small>mdi mdi-download</v-icon>
            </a>
          </template>
          <span>Descargar documento</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a
              class="d-flex align-center justify-center px-2 py-2 rounded-pill white--text"
              style="
                width: 36px;
                height: 36px;
                float: left;
                background-color: var(--v-primary-base);
              "
              v-bind="attrs"
              v-on="on"
              @click="srcComputed = null"
            >
              x
            </a>
          </template>
          <span>Cerrar</span>
        </v-tooltip>
      </div>
      <vue-pdf-embed style="max-width: 100%; clear: both" :source="srcComputed">
        Test
      </vue-pdf-embed>
      <div v-if="error">
        <v-card>
          <v-card-title>No se pudo cargar</v-card-title>
          <v-card-text>
            Si se sigue presentando este error, por favor contacte al
            administrador del sistema.
          </v-card-text>
        </v-card>
      </div>
    </div>
  </Fragment>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "PdfModalClienteViejo",
  props: {
    permitirDescargar: { type: Boolean, default: true },
  },
  components: {
    VuePdfEmbed,
  },
  data: () => ({
    src: null,
    error: false,
  }),
  methods: {
    descargarArchivo() {
      const link = document.createElement("a");
      link.href = this.src;
      link.download = `documento`;
      link.click();
    },
    limpiar(){
      this.src = null;
    },
    async obtenerArchivo(ruta, disk) {
      try {
        const { data, headers } =
          await this.services.Sanciones.descargarArchivo({
            ruta,
            disk,
          });
        const blob = new Blob([data], {
          type: headers["content-type"],
        });
        const file = new File([blob], "documento.pdf", {
          type: headers["content-type"],
        });
        this.src = window.URL.createObjectURL(file);
      } catch (error) {
        this.error = true;
      }
    },
  },
  computed: {
    srcComputed: {
      set: function (val) {
        this.src = val;
      },
      get: function () {
        return this.src;
      },
    },
  },
};
</script>
